import React from "react";
import LogoCombinedSeason from "../../assets/images/logo-combined-season.webp";
import Modal from "../../common/components/molecules/Modal/Modal";
import './signout.scss';

const Signout = () => {
    return (

        <div className="page-intro page-signout">
            <Modal
                className="signed-out"
                header={
                    <img
                        src={LogoCombinedSeason}
                        alt="Game Factory season logo"
                    />
                }
                footer={
                    <>
                        <a
                            href="/"
                            title="Return to the game"
                        >
                            <button
                                className="btn blank"
                                type="button"
                            >
                                Cancel
                            </button>
                        </a>

                        <button
                            className="btn"
                            form="signout-form"
                            type="submit"
                        >
                            Sign Out
                        </button>
                    </>
                }
            >
                <form
                    id="signout-form"
                    method="get"
                    action={process.env.REACT_APP_WS_URL + '/logout'}
                >
                    <h1>Sign out</h1>
                    <p>Are you sure you wish to sign-out?</p>
                </form>
            </Modal>
        </div>
    );
}

export default Signout;
