class SeededGame {
    defaults = {
        speed: 1000,
        maxTicks: 0,
        ideaSuccessChance: 0.4,
        studioSuccessChance: 0.4,
    }

    createSeed() {
        return [Math.floor(Math.random() * 999999999), Math.floor(Math.random() * 999999999)];
    }

    constructor(props) {
        this.initialSeed = props?.seed ?? null;
        this.seed = this.initialSeed;
        this.tick = props?.tick ?? 0;
        // this.score = props.score ?? 0;
        this.maxTicks = props?.maxTicks ?? this.defaults.maxTicks;
        this.ideaSuccessChance = props?.ideaSuccessChance ?? this.defaults.ideaSuccessChance;
        this.studioSuccessChance = props?.studioSuccessChance ?? this.defaults.studioSuccessChance;
        this.renewSeedFunc = props?.renewSeedFunc ?? (() => {
            return this.createSeed();
        });
        this.hopper = props?.hopper ?? [];
        this.hopperSize = props?.hopperSize ?? 100;
        this.ideas = ["2"];
    }

    increaseTick() {
        if (this.tick === this.maxTicks) {
            this.seed = this.renewSeedFunc();
            this.tick = 0;
        }
        this.tick++;
    }

    randomVal(max, min, seed=0) {
        max = max || 1;
        min = min || 0;

        this.seed[seed] = (this.seed[seed] * 9301 + 49297) % 233280;
        const rnd = this.seed[seed] / 233280;
        return Math.round(min + rnd * (max - min));
    }

    randomType() {
        return this.ideas[this.randomVal(this.ideas.length - 1, 0)];
    }

    randomSuccess(chance, seed=0) {
        return this.randomVal(100, 0, seed) < (chance * 100);
    }

    nextIdea(seed=0) {
        this.increaseTick();
        return {
            t: this.randomType(seed),
            s: this.randomSuccess(this.ideaSuccessChance, seed),
            k: this.tick,
        };
    }

    addToHopper(i) {
        if (this.hopper.length < this.hopperSize) {
            this.hopper.push(i);
        }
    }

    nextStudio(seed=1) {
        const i = this.hopper.shift();
        if (i) {
            this.increaseTick();
            i.s = this.randomSuccess(this.studioSuccessChance, seed);
        }
        return i;
    }
}
export default SeededGame;
