import React from 'react';
import './offline-earnings.css';
import LogoCombinedSeason from './../../../../assets/images/logo-combined-season.webp';
import Modal from "../../../../common/components/molecules/Modal/Modal";
import Unicorn from "./../../../../assets/images/unicorn.png";


const GameOfflineEarnings = ({
    earnings = 0,
    ...props
}) => {
    //  emit this out so the parent can handle presentation logic
    const closeAndContinue = () => {
        if (props.onMouseDown) {
            props.onMouseDown();
        }
    }

    return (
        <main className="offline-earnings">
            <Modal
                header={
                    <img
                        className="logo"
                        src={LogoCombinedSeason}
                        alt="Game Factory season logo"
                    />
                }
                type="has-icon"
            >
                <div className="asset-container">
                    <img
                        className="asset"
                        src={Unicorn}
                        alt="Unicorn"
                    />
                </div>

                <p>Nice to have you back! While you were away your generated</p>
                <h1>${earnings.toLocaleString()}</h1>

                <button
                    className="btn"
                    onMouseDown={closeAndContinue}
                >
                    Continue
                </button>
            </Modal>
        </main>
    );
};

export default GameOfflineEarnings;
