import React from 'react';
import './leaderboard-card.scss';
import Icon from "../../atoms/Icon/Icon";
import { getFactoryLogo } from "../../../../../src/common/utils/factoyLogos";


function ordinal_suffix_of(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

const LeaderboardCard = ({
    cardData = {
        "name": "Game Worx",
        "icon": "https://placehold.co/64x64",
        "revenue": "124018991",
        "joinReward": "30%",
        "members": "18129",
    },
    ...props
}) => {
    const componentName = 'leaderboard-card';
    const canJoin = props?.canJoin ?? true;   //  TODO not sure if will be passed in as prop or local logic needing useState (so setting like this for now)
    const isLocked = props?.isLocked ?? false;  //  TODO not sure if will be passed in as prop or local logic needing useState (so setting like this for now)

    const handleJoinFactory = () => {
        props.handleJoinFactory(cardData);
    }

    return (
        <article className={componentName}>
            <header className="header">
                <img
                    className="icon-factory-logo"
                    src={getFactoryLogo(cardData.name)}
                    alt={`${cardData.name} icon`}
                />
                <b>{cardData.name}</b>
                <span className="rank">
                    {props?.pos === 1 && (
                        <Icon name="trophy" />
                    )}
                    {ordinal_suffix_of(props?.pos ?? 1)}
                </span>
            </header>

            <ul className="stats-container">
                <li>
                    <span className="sub">Game revenue</span>
                    {/*TODO can do better than this, but for now... */}
                    <b>${Number(cardData.revenue).toLocaleString()}</b>
                </li>
                <li className="join-reward">
                    <span className="sub">Join reward</span>
                    {/*TODO can do better than this, but for now... */}
                    <b>${cardData.joinReward.toLocaleString()}</b>
                </li>
                <li className="members">
                    <span className="sub">Members</span>
                    {/*TODO can do better than this, but for now... */}
                    <b>{Number(cardData.members).toLocaleString()}</b>
                </li>
            </ul>

            {canJoin && (
                <button
                    className="btn small"
                    disabled={isLocked}
                    onMouseDown={handleJoinFactory}
                >
                    {isLocked && (
                        <Icon name="padlock-closed" />
                    )}
                    Join this factory
                </button>
            )}
        </article>
    );
};

export default LeaderboardCard;
