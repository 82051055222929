import React from 'react';
import IconFactory from './../../../../assets/images/factory-icon-isolated.webp';
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";

const IntroJoinFactory = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconFactory}
                    alt="Game Factory icon"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1 className="modal-content-header">Select a Factory</h1>

                <p>First, select a factory and claim your join reward. Play to generate big revenues and earn your share of a huge $SLING prize pool!</p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroJoinFactory;
