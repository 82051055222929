import React from 'react';
import './detector-machine.scss';
import ImageMachineDetector from "../../../../assets/images/game-assets/ideas/detector.png";

const DetectorMachine = ({
    status,     //  deciding | pass | fail
    ...props
}) => {
    const componentName = 'detector-machine';

    return (
        <div className={`${componentName} ${status}`}>
            <img
                className="detector"
                src={ImageMachineDetector}
                alt="IdeaConcept detector machine"
            />
        </div>
    );
};

export default DetectorMachine;
