import { useQuery } from '@tanstack/react-query';
import { httpGet } from "./httpGet";

export const useGetLeaderboard = () => useQuery(
    {
        queryKey: ['leaderboard'],
        queryFn: () => httpGet(process.env.REACT_APP_WS_URL + '/score'),
        refetchOnMount: true
    }
);
