import React from 'react';
import './progress-indicator.scss';


const ProgressIndicator = ({
    stages = 4,
    activeStage = 0,
    onStageChange,
   ...props
} ) => {
    const componentName = 'progress-indicator';

    return (
        <ul className={componentName}>
            {Array.from({ length: stages }).map((_, index) => (
                <li
                    key={index}
                    className={index === activeStage ? 'active' : ''}
                    onClick={() => onStageChange(index)}
                >
                    <span className="accessible-text">Stage {index + 1}</span>
                </li>
            ))}
        </ul>
    );
};

export default ProgressIndicator;
