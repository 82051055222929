import React, { useState } from 'react';
import './game-container.css';
import useWebSocket from "react-use-websocket";
import ImageMachineLeftIdeas from '../../../assets/images/game-assets/ideas/machine-left-front.webp';
import { boostPrices } from "../../../data/boostPrices";
import ImageMachineRightIdeas from './../../../assets/images/game-assets/ideas/machine-right-front.webp';
import ImageMachineLeftStudio from '../../../assets/images/game-assets/studio/machine-left-front.webp';
import ImageMachineRightStudio from './../../../assets/images/game-assets/studio/machine-right-front.webp';
import ImageMachineLeftLaunch from '../../../assets/images/game-assets/launch/machine-left-front.webp';
import LogoStandaloneSeason from '../../../../src/assets/images/logo-standalone-season.webp';
import BeltLaunch from '../../../assets/images/game-assets/belt-launch.webp';
import IdeaBoostButtonActive from '../../../assets/images/game-assets/ideas/boost-button-active.png';
import IdeaBoostButtonInactive from '../../../assets/images/game-assets/ideas/boost-button-hover.png';
import StudioBoostButtonActive from '../../../assets/images/game-assets/studio/boost-button-active.png';
import StudioBoostButtonInactive from '../../../assets/images/game-assets/studio/boost-button-hover.png';
import LaunchBoostButtonActive from '../../../assets/images/game-assets/launch/boost-button-active.png';
import LaunchBoostButtonInactive from '../../../assets/images/game-assets/launch/boost-button-hover.png';
import HelpCta from '../../../assets/images/game-assets/help-cta.png';
import DetectorMachine from "../../../common/components/molecules/DetectorMachine/DetectorMachine";
import ConveyorBelt from "../../../common/components/molecules/ConveyorBelt/ConveyorBelt";
import Screen from "../../../common/components/molecules/Screen/Screen";
import IdeaConcept from "../../../common/components/atoms/IdeaConcept/IdeaConcept";
import Icon from "../../../common/components/atoms/Icon/Icon";
import IdeaLaunch from "../../../common/components/atoms/IdeaLaunch/IdeaLaunch";
import IdeaStudio from "../../../common/components/atoms/IdeaStudio/IdeaStudio";


const GameContainer = ({
    className,
    boostClicked,
    toggleContent,
    ...props
}) => {
    const componentName = 'game-container';
    const [detectorStatus, setDetectorStatus] = useState('deciding');
    // const [studioLocked, setStudioLocked] = useState(true);
    // const [launchLocked, setLaunchLocked] = useState(true);
    const studioLocked = props?.studioLocked ?? true;
    const launchLocked = props?.launchLocked ?? true;
    // const [launchScreenValue, setLaunchScreenValue] = useState(0);
    const launchScreenValue = 0
    const factoryLocked = props?.factoryLocked;
    const conveyorSpeed = props?.conveyorSpeed ?? 1;
    const [isActiveBoost, setActiveBoost] = useState(null);


    const { sendJsonMessage } = useWebSocket(
        process.env.REACT_APP_WS_URL.replace('http', 'ws'),
        {
            share: true,
            shouldReconnect: () => true,
        },
    );

    //  TODO WIP
    const handleAnimationEnd = (status) => {
        setDetectorStatus(status);
        setTimeout(() => {
            setDetectorStatus('deciding');
        }, 250); // revert back to 'deciding'
    };

    //  is each stage locked/game running?
    const startFactory = () => {
        console.log('Start Factory Pushed')
        props?.onFactoryStart();
    }

    const startStudio = () => {
        // setStudioLocked(false);
        console.log('Start Studio Pushed')
        props?.onStudioStart();
    }

    const startLaunch = () => {
        // setLaunchLocked(false);
    }

    const handleBoostCTAClicked = (clicked) => {
        setActiveBoost(isActiveBoost === clicked ? null : clicked);
        boostClicked(clicked);  //  emit out for the parent to show/handle the boost drawer
    }

    return (
        <section className={`${componentName} ${className}`}>
            <div className={`game-container-header ${factoryLocked ? '' : ''}`}>
                <img
                    className="logo"
                    src={LogoStandaloneSeason}
                    alt="Game Factory Season 1 logo"
                />

                <p className="revenue-total">
                    <span className="caption">My Revenue</span>
                    {/*TODO not sure if toLocalString correct way to do this */}
                    <b>${props?.score.toLocaleString()}</b>
                </p>
            </div>

            <article className="conveyor-container ideas">
                <img
                    className="machine-top-left"
                    src={ImageMachineLeftIdeas}
                    alt="Part of the machine"
                />

                <div className="idea-collection">
                    {!factoryLocked && (
                        <>
                            {props?.ideas.map(i => (
                                <IdeaConcept
                                    key={i.k}
                                    ideaType={"type" + (i?.t ?? "1")}
                                    onAnimationEnd={(pos) => {
                                        if (pos === 'decision') {
                                            handleAnimationEnd(i?.s ? 'pass' : 'fail');
                                        }
                                        if (pos === 'end') {
                                            props?.onEndItemAnimation(i);
                                            if (typeof i?.onEnd === 'function') {
                                                i.onEnd();
                                            }
                                        }
                                    }}
                                    scroreEnd={i.scoreEnd}
                                    scroreStart={i.scoreStart}
                                    speed={factoryLocked ? '0' : conveyorSpeed}
                                    status={i?.s ? 'pass' : 'fail'}
                                />
                            ))}
                        </>
                    )}
                </div>

                <button
                    className={`
                        ${isActiveBoost === 'ideas' ? 'active' : ''}
                        btn blank
                        boost-cta
                    `}
                    onClick={() => handleBoostCTAClicked('ideas')}
                >
                    <img
                        alt="Boost ideas button"
                        src={isActiveBoost === 'ideas' ? IdeaBoostButtonActive : IdeaBoostButtonInactive}
                    />
                </button>

                <DetectorMachine status={detectorStatus}/>

                <ConveyorBelt speed={factoryLocked ? '0' : conveyorSpeed}/>

                <img
                    className="machine-top-right"
                    src={ImageMachineRightIdeas}
                    alt="Part of the machine"
                />

                {factoryLocked && (
                    <div className="locked-container">
                        <button
                            className="btn"
                            onMouseDown={startFactory}
                        >
                            Get Started
                        </button>
                    </div>
                )}
            </article>


            <article className={`conveyor-container studio ${studioLocked ? 'locked' : ''}`}>
                <div className="pipe-container">
                    <Screen
                        onClick={() => {
                            if (props?.turbo ?? false) {
                                sendJsonMessage({ event: "t1", data: 0 });
                            }
                        }}
                        value={props?.studioScreenValue ?? 0}
                        turbo={props?.turbo ?? false}
                    />
                </div>

                <img
                    className="machine-top-left"
                    src={ImageMachineLeftStudio}
                    alt="Part of the machine"
                />

                <div className="idea-collection">
                    {!studioLocked && (
                        <>
                            {props?.studio.map(i => (
                                <IdeaStudio
                                    key={i.k}
                                    ideaType={"type" + (i?.t ?? "1")}
                                    onAnimationEnd={(pos) => {
                                        if (pos === 'decision') {
                                            // do nothing
                                        }
                                        if (pos === 'end') {
                                            props?.onEndItemAnimation(i, true);
                                            if (typeof i?.onEnd === 'function') {
                                                i.onEnd();
                                            }
                                        }
                                    }}
                                    scroreEnd={i.scoreEnd}
                                    scroreStart={i.scoreStart}
                                    speed={factoryLocked ? '0' : conveyorSpeed}
                                    status={i?.s ? 'pass' : 'fail'}
                                />
                            ))}
                        </>
                    )}
                </div>

                <button
                    className={`
                        ${isActiveBoost === 'studio' ? 'active' : ''}
                        btn blank
                        boost-cta
                    `}
                    onClick={() => handleBoostCTAClicked('studio')}
                >
                    <img
                        alt="Boost ideas button"
                        src={isActiveBoost === 'studio' ? StudioBoostButtonActive : StudioBoostButtonInactive}
                    />
                </button>

                <ConveyorBelt speed={studioLocked ? '0' : conveyorSpeed}/>

                <img
                    className="machine-top-right"
                    src={ImageMachineRightStudio}
                    alt="Part of the machine"
                />

                {studioLocked && (
                    <div className="locked-container">
                        <button
                            className="btn"
                            onMouseDown={startStudio}
                            disabled={props.score < boostPrices.studio}
                        >
                            <Icon name="padlock-closed" />
                            Unlock $1,000,000
                        </button>
                    </div>
                )}
            </article>


            <article className="conveyor-container launch">
                <div className="pipe-container">
                    <Screen value={launchScreenValue}/>
                </div>

                {!launchLocked && (
                    <div className="idea-collection">
                        <IdeaLaunch
                            onAnimationEnd={() => {
                                console.log("launch item finished end animation");
                            }}
                            speed={launchLocked ? '0' : conveyorSpeed}
                        />
                    </div>
                )}

                <button
                    className={`
                        ${isActiveBoost === 'launch' ? 'active' : ''}
                        btn blank
                        boost-cta
                    `}
                    onClick={() => handleBoostCTAClicked('launch')}
                >
                    <img
                        alt="Boost ideas button"
                        src={isActiveBoost === 'launch' ? LaunchBoostButtonActive : LaunchBoostButtonInactive}
                    />
                </button>

                <img
                    className="launch-pad"
                    src={BeltLaunch}
                    alt="IdeaLaunch pad for ideas"
                />

                <img
                    className="machine-top-left"
                    src={ImageMachineLeftLaunch}
                    alt="Part of the machine"
                />

                {launchLocked && (
                    <div className="locked-container">
                        <button
                            className="btn"
                            onMouseDown={startLaunch}
                            disabled
                        >
                            <Icon name="padlock-closed"/>
                            Locked
                        </button>
                    </div>
                )}
            </article>

            <img
                alt="Help/Info CTA"
                className="help-cta"
                onClick={() => {toggleContent('help')}}
                src={HelpCta}
            />
        </section>
    );
};

export default GameContainer;

