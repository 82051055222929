import React from 'react';
import './nav-item.scss';

const NavItem = ({
     isActive,
     children,
     ...props
 }) => {
    const componentName = 'nav-item';

    return (
        <div
            className={`${componentName} ${isActive ? 'active' : ''}`}
            onMouseDown={props.clicked}
        >
            {children}
        </div>
    );
};

export default NavItem;

