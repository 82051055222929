import React from 'react';
import Icon from "../../../../../common/components/atoms/Icon/Icon";
import BoostCard from "../../../../../common/components/organisms/BoostCard/BoostCard";


const BoostFollowX = ({
    className,
    ...props
}) => {
    const componentName = 'boost-follow-x';

    //  dummy some data
    const cardData = [
        {
            "cost": "0",
            "improvement_key": null,
            "improvement_value": null,
            "locked":  !(props.upgrades?.['uu0'] ?? false)|| props.upgrades?.['ub0'],
            "tag_key": "mega",
            "tag_value": "$$$",
            "type": "$5000 Bonus",
            "label": props.upgrades?.['ub0'] ? "Claimed" : "Get",
        }
    ]

    return (
        <section className={`boosts-container ${componentName} ${className}`}>
            <div className="header">
                <p>
                    <Icon name="x" />
                    {((!props.upgrades?.['uu0'] ?? true) && (
                        <b>Follow us on X to Unlock</b>
                    )) || <b>Boosts</b>}
                </p>

                <button
                    className="btn small"
                    onMouseDown={() => {
                        window.open(process.env.REACT_APP_X_URL, '_blank').focus();
                        props?.buyUpgrade('uu0');
                    }}
                    disabled={props.upgrades?.['uu0'] ?? false}
                >
                    Follow
                </button>
            </div>

            {cardData.map((item, index) => (
                <BoostCard
                    key={index}
                    cardData={item}
                    clicked={(d) => {
                        props?.buyUpgrade('ub0');
                    }}
                />
            ))}
        </section>
    );
};

export default BoostFollowX;

