import React from 'react';
import './leaderboard.scss';
import { motion } from 'framer-motion';
import Icon from "../../../../common/components/atoms/Icon/Icon";
import LeaderboardCard from "../../../../common/components/organisms/LeaderboardCard/LeaderboardCard";
import { useGetLeaderboard } from "../../../../data/useGetLeaderboard";


const GameLeaderBoard = (props) => {
    const {isPending, isError, data} = useGetLeaderboard();

    if (isPending) {
        // loading state
        return <></>;
    }

    if (isError) {
        // error page
        return <>
            <h1>
                <Icon name="trophy"/>
                Leaderboard
            </h1>

            <p className="game-modal-sub-heading small">Unknown error getting leaderboard</p>
        </>
    }

    return (
        <>
            <h1>
                <Icon name="trophy"/>
                Leaderboard
            </h1>

            <p className="game-modal-sub-heading small">{data[0].name} is currently winning!</p>

            <section className="leaderboard-cards-container">
                {data.map((f, index) => (
                    <motion.div
                        key={index}
                        initial={{ blur: '20px', opacity: 0, scale: 0.3, y: -20 }}
                        animate={{ blur: '0px', opacity: 1, scale: 1, y: 0 }}
                        transition={{
                            delay: index * .1, // Staggered delay based on index
                            duration: .2,
                            ease: 'easeInOut',
                        }}
                    >
                        <LeaderboardCard
                            key={index}
                            pos={index + 1}
                            cardData={f}
                            canJoin={false}
                            handleJoinFactory={(factory) => {
                                console.log("Join this factory: " + factory.id);
                            }}
                        />
                    </motion.div>
                ))}
            </section>
        </>
    );
};

export default GameLeaderBoard;
