import React from 'react';
import './conveyor-belt.scss';

const ConveyorBelt = ({
    speed,
    direction,
    ...props
}) => {
    const componentName = 'conveyor-belt';

    return (
        <div className={componentName}>
            <div className={`belt speed-${speed} ${direction}`}>
                <span className="accessible-text">Conveyor belt moving across the screen</span>
            </div>
        </div>
    );
};

export default ConveyorBelt;
