import React, {useEffect, useRef, useState} from 'react';
import './idea-studio.scss';
import ImageIdeaType1 from "../../../../assets/images/game-assets/studio/studio-unicorn.png";
import ImageIdeaType2 from "../../../../assets/images/game-assets/studio/studio-treasure.png";
import ImageIdeaType3 from "../../../../assets/images/game-assets/studio/studio-boxing-glove.png";
import ImageIdeaType4 from "../../../../assets/images/game-assets/studio/studio-flask.png";
import ImageIdeaType5 from "../../../../assets/images/game-assets/studio/studio-gems.png";
import ImageIdeaType6 from "../../../../assets/images/game-assets/studio/studio-quest.png";
import ImageIdeaType7 from "../../../../assets/images/game-assets/studio/studio-football.png";
import ImageIdeaType8 from "../../../../assets/images/game-assets/studio/studio-wheel.png";
import ImageIdeaType9 from "../../../../assets/images/game-assets/studio/studio-helmet.png";

const imageMap = {
    type1: ImageIdeaType1,
    type2: ImageIdeaType2,
    type3: ImageIdeaType3,
    type4: ImageIdeaType4,
    type5: ImageIdeaType5,
    type6: ImageIdeaType6,
    type7: ImageIdeaType7,
    type8: ImageIdeaType8,
    type9: ImageIdeaType9,
};

const IdeaStudio = ({
    ideaType = 'type1',
    onAnimationEnd,
    scroreEnd,
    scroreStart,
    speed,
    status,
    ...props
}) => {
    const componentName = 'idea-studio';
    const ideaRef = useRef(null);
    const [ideaState, setIdeaState] = useState('deciding');

    useEffect(() => {
        const handleAnimationEnd = (event) => {
            if (event.animationName === 'preDecide') {
                onAnimationEnd('decision', status);   //  emits
            }

            if (event.animationName === 'postDecide' || event.animationName === 'ideaFade') {
                onAnimationEnd('end', status);   //  emits
            }

            setIdeaState(status);
        };

        const ideaElement = ideaRef.current;
        if (ideaElement) {
            ideaElement.addEventListener('animationend', handleAnimationEnd);
        }

        return () => {
            if (ideaElement) {
                ideaElement.removeEventListener('animationend', handleAnimationEnd);
            }
        };
    }, [onAnimationEnd]);

    return (
        <div
            className={`${componentName} speed-${speed} ${ideaState}`}
            ref={ideaRef}
        >
            <img
                src={imageMap[ideaType]}
                alt="An idea being turned into a game by a studio"
            />
            <span className="loader"></span>

            {ideaState === 'fail' && (
                <img
                    alt="This idea has been cancelled"
                    className="cancelled"
                    src="/images/cancelled.png"
                />
            )}

            <span className="score start">+{scroreStart}</span>

            {ideaState !== 'fail' && (
                <span className="score end">${scroreEnd}</span>
            )}
        </div>
    );
};

export default IdeaStudio;
