import React from 'react';
import Icon from "../../../../../common/components/atoms/Icon/Icon";
import BoostCard from "../../../../../common/components/organisms/BoostCard/BoostCard";

const BoostInvite = ({
    className,
    isLocked = true,
    ...props
}) => {
    const componentName = 'boost-factory';
    const cardData = [
        {
            "cost": "0",
            "improvement_key": null,
            "improvement_value": null,
            "locked": !(props.upgrades?.['uu2'] ?? false)|| props.upgrades?.['ub2'],
            "tag_key": "mega",
            "tag_value": "$$$",
            "type": "$100000 Bonus",
            "label": props.upgrades?.['ub2'] ? "Claimed" : "Get",
        },
    ];
    // click of the main CTA  (continue)
    const handleContinueClick = () => {
        alert('Probably calling a method in the Game class?');
    };

    // click of the card
    const handleCardClick = (data) => {
        alert('clicked card (console logged data)');
        console.log('card data: ', data);
    };

    return (
        <section className={`boosts-container ${componentName} ${className}`}>
            <div className="header">
                <p>
                    <Icon name="users"/>
                    <b className="small">{props?.invite[0] ?? 0} of 5 Friends Invited</b>
                </p>
                <button
                    className="btn small"
                    onMouseDown={async () => {
                        if (navigator?.clipboard?.writeText) {
                            await navigator.clipboard.writeText('https://gf.slingshotdao.com?f=' + (props?.invite[1] ?? ''));
                        }
                    }}
                >
                    Copy link
                </button>
            </div>

            {cardData.map((item, index) => (
                <BoostCard
                    key={index}
                    cardData={item}
                    clicked={(d) => {
                        props?.buyUpgrade('ub2');
                    }}
                />
            ))}
        </section>
    );
};

export default BoostInvite;

