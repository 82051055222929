import React from 'react';
import { getFactoryLogo } from "../../../../../src/common/utils/factoyLogos";
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";

const factoryInfo = ({
    currentFactory: factory,
     ...props
}) => {
    return (
        <>
            <MotionFadeInUp className="icon-container small">
                <img
                    className="stage-icon"
                    src={getFactoryLogo(factory.name ? factory.name : '')}
                    alt="Game Factory icon"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1>{factory.name ? factory.name : ''}</h1>
                <p>
                    You will gain in-game revenue for each winning idea. To earn more revenue you need to boost your game creation machine.
                    <br />
                    <br />
                    Good luck!
                </p>
            </MotionSlideInFromRight>
        </>
    );
};

export default factoryInfo;
