import React from 'react';
import IconGetToTheTop from "../../../../assets/images/get-to-the-top-icon.webp";
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";

const IntroGetToTheTop = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconGetToTheTop}
                    alt="Game Factory icon"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1>Get to the top</h1>

                <p>
                    Compete against the other factories to take the top place on the board. If your factory wins, you win an
                    equal cut of a huge $SLING prize pool!
                </p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroGetToTheTop;
