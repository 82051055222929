import React from 'react';
import './modal.scss';
import { motion } from 'framer-motion';


type ModalProps = {
    className?: string;
    header?: React.ReactNode;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    type?: '' | 'has-icon';
};

const Modal = ({
    className,
    header,
    children,
    footer,
    type = '',
}: ModalProps) => {
    const componentName = 'modal';
    const hasHeader = !!header;
    const hasFooter = !!footer;

    return (
        <div className={`${componentName} ${className} ${type}`}>
            {hasHeader && (
                <motion.div className="modal-header">
                    {header}
                </motion.div>
            )}

            <motion.div
                className="modal-content"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: .15, ease: 'easeInOut' }}
            >
                {children}
            </motion.div>

            {hasFooter && (
                <aside className="modal-ctas">
                    {footer}
                </aside>
            )}
        </div>
    );
};

export default Modal;
