import React from 'react';
import IconBoostProductiopn from "../../../../assets/images/boost-production.webp";
import MotionSlideInFromLeft from "../../../../common/components/_animation/MotionSlideInFromLeft";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";

const IntroBoostProduction = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconBoostProductiopn}
                    alt="Game Factory icon"
                />
            </MotionFadeInUp>

            <MotionSlideInFromLeft>
                <h1>Upgrade Production</h1>

                <p>
                    Utilise boosts and level-ups to upgrade the efficiency of your production line. The faster it runs, the
                    more revenue you generate!
                </p>
            </MotionSlideInFromLeft>
        </>
    );
};

export default IntroBoostProduction;
