import React from 'react';
import './home.scss';
import { useGetUser } from "../../data/useGetUser";


const Home = () => {
    const {isPending, isError, data, error} = useGetUser();

    if (isPending) {
        // loading state
        return <div className='page-home'></div>;
    }

    if (isError) {
        // error page
        return <div className='page-home'>Error: {error?.message ?? 'Unknown error'}</div>
    }

    if (typeof data?.u !== 'undefined') {
        // redirect logged-in user
        window.location.replace("/game");
    }

    // not logged in
    return (<>Redirect</>)
}

export default Home;
