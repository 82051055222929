import React from 'react';
import './screen.scss';
import Icon from '../../atoms/Icon/Icon';

const Screen = ({
    onClick,
    turbo = false,
    value,
    ...props
}) => {
    const componentName = 'screen';

    return (
        <p
            className={componentName}
            onClick={onClick}
        >
            {value}

            {turbo && (
                <span>
                    <Icon name="gem" />
                    TURBO
                </span>
            )}
        </p>
    );
};

export default Screen;
