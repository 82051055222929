import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

const Icon = ({ name }) => {
    const [path, setPath] = useState('');

    // Imports the SVG
    useEffect(() => {
        const importIcon = async () => {
            try {
                const icon = await import(`../../../../assets/icons/${name}.svg`);
                setPath(icon.default);
            } catch (e) {
                setPath('');
            }
        };
        importIcon();
    }, [name]);

    return (
        <SVG
            className={`icon icon-${name}`}
            src={path}
        />
    );
};

export default Icon;
