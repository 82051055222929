import React, { useState } from 'react';
import "./intro.css";
import LogoCombinedSeason from './../../assets/images/logo-combined-season.webp';
import LogoStandaloneSeason from './../../assets/images/logo-standalone-season.webp';
import Game from './../../actions/game.js';
import Modal from '../../common/components/molecules/Modal/Modal';
import FactoryList from "./_sub/factoryList/factoryList";
import IntroJoinFactory from "./_sub/introJoinFactory/introJoinFactory";
import IntroBoostProduction from "./_sub/introBoostProduction/introBoostProduction";
import IntroGetToTheTop from "./_sub/introGetToTheTop/introGetToTheTop";
import ProgressIndicator from "../../common/components/molecules/ProgressIndicator/ProgressIndicator";
import IntroSwitchItUp from "./_sub/introSwitchItUp/introSwitchItUp";
import FactoryInfo from "./_sub/factoryInfo/factoryInfo";


const Intro = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isFactoryInfoModalOpen, setIsFactoryInfoModalOpen] = useState(false);
    const [activeStage, setActiveStage] = useState(0);
    const [selectedFactory, setSelectedFactory] = useState(null);

    const handleStageChange = (newStage) => {
        if (newStage >= 0 && newStage < contentStages.length) {
            setActiveStage(newStage);
        }
    };

    const handleJoinFactory = (factory, id) => {
        setSelectedFactory(factory);
        setIsModalOpen(false);
        setIsFactoryInfoModalOpen(true);
    };

    const contentStages = [
        {
            component: <IntroJoinFactory onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
        {
            component: <IntroBoostProduction onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
        {
            component: <IntroGetToTheTop onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
        {
            component: <IntroSwitchItUp onClick={() => handleStageChange(activeStage + 1)} />,
            showHeader: true,
            showFooter: true,
        },
        {
            component: (
                <FactoryList
                    joinFactory={handleJoinFactory}
                    onClick={() => handleStageChange(activeStage - 1)}
                />
            ),
            showHeader: false,
            showFooter: false,
        },
    ];

    const renderModalContent = () => {
        const content = contentStages[activeStage];
        if (!content) return null;

        return (
            <Modal
                type={activeStage < contentStages.length - 1 ? 'has-icon' : ''}
                header={
                    content.showHeader && (
                        <img
                            className="into-header-logo"
                            src={LogoCombinedSeason}
                            alt="Game Factory season logo"
                            onMouseDown={() => {
                                setActiveStage(0);
                                setIsFactoryInfoModalOpen(false);
                            }}
                        />
                    )
                }
                footer={
                    content.showFooter && (
                        <button
                            className="btn"
                            onMouseDown={() => handleStageChange(activeStage + 1)}
                        >
                            Continue
                        </button>
                    )
                }
            >
                {content.component}

                {activeStage < contentStages.length - 1 && (
                    <ProgressIndicator
                        activeStage={activeStage}
                        onStageChange={handleStageChange}
                        stages={contentStages.length - 1}
                    />
                )}
            </Modal>
        );
    };

    const renderFactoryInfoModal = () => (
        <Modal
            type="has-icon"
            icon={selectedFactory.icon}
            header={
                <img
                    className="into-header-logo"
                    src={LogoStandaloneSeason}
                    alt="Game Factory season logo"
                />
            }
            footer={
                <>
                    <button
                        className="btn blank"
                        onMouseDown={() => {
                            setActiveStage(contentStages.length - 1);
                            setIsFactoryInfoModalOpen(false);
                            setIsModalOpen(true);
                        }}
                    >
                        Back
                    </button>
                    <button
                        className="btn"
                        onMouseDown={() => Game.joinFactory(selectedFactory.id)}
                    >
                        Continue
                    </button>
                </>
            }
        >
            <FactoryInfo currentFactory={selectedFactory} />
        </Modal>
    );

    return (
        <div className="page-intro">
            {isModalOpen && renderModalContent()}
            {isFactoryInfoModalOpen && renderFactoryInfoModal()}
        </div>
    );
};

export default Intro;
