import React from 'react';
import IconSwitchItUp from "../../../../assets/images/switch-factory-icon.webp";
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";

const IntroSwitchItUp = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconSwitchItUp}
                    alt="Game Factory icon"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1>Switch it up</h1>

                <p>You will soon be able to switch Factories and take your revenue with you! Strategic moves can earn big rewards... coming soon!</p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroSwitchItUp;
