import React from 'react';
import './boosts.scss';

import Icon from "../../../../common/components/atoms/Icon/Icon";
// import BoostFactory from "./_sub/boostsFactory";
import BoostFollowX from "./_sub/boostsFollowX";
import BoostJoinDiscord from "./_sub/boostsJoinDiscord";
// import BoostsLike from "./_sub/boostsLike";
// import BoostsRetweet from "./_sub/boostsRetweet";
// import BoostsTweet from "./_sub/boostsTweet";
import BoostInvite from "./_sub/boostsInvite";
import BoostJoinTelegram from "./_sub/boostsJoinTelegram";

const GameBoosts = (props) => {
    return (
        <>
            <h1>
                <Icon name="fire" />
                Boosts
            </h1>

            <p className="game-modal-sub-heading small">
                Your available balance is
                {/*TODO can do better than this, but for now... */}
                <b> ${props?.balance.toLocaleString() ?? 0}</b>
            </p>

            {/*<BoostFactory*/}
            {/*    upgrades={props?.upgrades}*/}
            {/*    factoryLocked={props?.factoryLocked ?? true}*/}
            {/*    buyUpgrade={props?.buyUpgrade}*/}
            {/*    balance={props?.balance ?? 0}*/}
            {/*/>*/}

            <BoostInvite
                buyUpgrade={props?.buyUpgrade}
                upgrades={props?.upgrades}
                invite={props?.invite}
            />

            <BoostFollowX
                buyUpgrade={props?.buyUpgrade}
                upgrades={props?.upgrades}
            />

            <BoostJoinDiscord
                upgrades={props?.upgrades}
                buyUpgrade={props?.buyUpgrade}
            />

            <BoostJoinTelegram
                upgrades={props?.upgrades}
                buyUpgrade={props?.buyUpgrade}
            />

            {/*https://t.me/SlingShotDAOGC*/}

            {/*<BoostsLike />*/}

            {/*<BoostsRetweet />*/}

            {/*<BoostsTweet />*/}

            {/*<BoostInvite isLocked={false} />*/}
        </>
    );
};

export default GameBoosts;
