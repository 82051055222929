import React from 'react';
import './boost-card.scss';

const BoostCard = ({
    cardData = {
        "cost": "[10.4m]",
        "improvement_key": "[LEVEL]",
        "improvement_value": "[50]",
        "locked": false,
        "tag_key": "factory",
        "tag_value": "[ideas]",
        "type": "[Generation Speed]"
    },
    ...props
}) => {
    const componentName = 'boost-card';

    // format the improvement cost string
    const formatCurrency = (str) => {
        const numberPart = str.replace(/[^0-9.]/g, ''); // Remove any non-numeric characters except the decimal point
        const number = Number(numberPart);  // Convert the string to a number
        const formattedNumber = number.toLocaleString();    // Format the number with toLocaleString

        // Add the $ back and return the result
        return `$${formattedNumber}`;
    };

    return (
        <article className={`${componentName} ${cardData.locked ? 'locked' : ''}`}>
            <div className="boost-type">
                <span className={`boost-tag ${cardData.tag_key}`}>{cardData.tag_value}</span>
                {cardData.type}
            </div>

            <ul className="boost-info">
                <li>
                    <span className="caption">COST</span>
                    {formatCurrency(cardData.cost)}
                </li>
                <li>
                    <span className="caption">{cardData.improvement_key}</span>
                    {cardData.improvement_value}
                </li>
                <li>
                    <button
                        className="btn small"
                        onMouseDown={() => {
                            props.clicked(cardData);
                        }}
                    >
                        {cardData?.label ?? "???"}
                    </button>
                </li>
            </ul>
        </article>
    );
};

export default BoostCard;

