import React from 'react';
import './factory-list.css';
import { motion } from 'framer-motion';
import LeaderboardCard from "../../../../common/components/organisms/LeaderboardCard/LeaderboardCard";
import Icon from "../../../../common/components/atoms/Icon/Icon";
import { useGetLeaderboard } from "../../../../data/useGetLeaderboard";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";


const FactoryList = (props) => {
    const { isPending, isError, data } = useGetLeaderboard();

    if (isPending) {
        // loading state
        return <></>;
    }

    if (isError) {
        // error page
        return (
            <>
                <h1 className="modal-content-header">
                    <Icon name="trophy" />
                    Leaderboard
                </h1>
                <p className="game-modal-sub-heading small">Unknown error getting leaderboard</p>
            </>
        );
    }

    return (
        <>
            <MotionFadeInUp>
                <h1 className="modal-content-header">
                    <Icon name="trophy" />
                    Join a Factory
                </h1>

                <p className="small">
                    Choose wisely, you can only switch to another factory once you reach $1,000,000 in revenue.
                </p>

                <section className="join-factory-card-container">
                    {data.map((cardData, index) => (
                        <motion.div
                            key={index}
                            initial={{ blur: '20px', opacity: 0, scale: 0.3, y: -20 }}
                            animate={{ blur: '0px', opacity: 1, scale: 1, y: 0 }}
                            transition={{
                                delay: index * .1, // Staggered delay based on index
                                duration: .2,
                                ease: 'easeInOut',
                            }}
                        >
                            <LeaderboardCard
                                pos={index + 1}
                                cardData={cardData}
                                handleJoinFactory={(factory) => {
                                    props.joinFactory(factory, index);
                                }}
                            />
                        </motion.div>
                    ))}
                </section>

                <button
                    className="btn join-factory-close-cta"
                    onMouseDown={props.onClick}
                >
                    Close
                </button>
            </MotionFadeInUp>
        </>
    );
};

export default FactoryList;
