import React from 'react';
import "./signed-out.css";
import Modal from "../../common/components/molecules/Modal/Modal";
import LogoCombinedSeason from "../../assets/images/logo-combined-season.webp";


const SignedOut = () => {
    return (
        <div className="page-intro">
            <Modal
                className="signed-out"
                header={
                    <img
                        src={LogoCombinedSeason}
                        alt="Game Factory season logo"
                    />
                }
                footer={
                    <a
                        href="/"
                        title="Login"
                    >
                        <button className="login-cta btn">
                            Login
                        </button>
                    </a>
                }
            >
                <h1>You have been logged out</h1>

                <p>Thanks for playing SlingShot's Game Factory</p>
            </Modal>
        </div>
    );
};


export default SignedOut;
