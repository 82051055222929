'use client'

import React from 'react';
import { motion } from 'framer-motion';

type MotionProps = {
    className?: string;
    children: React.ReactNode;
};

const MotionSlideInFromRight = ({
    className,
    children
}: MotionProps) => {
    return (
        <motion.div
            className={className}
            initial={{
                opacity: 0,
                x: -40
            }}
            animate={{
                opacity: 1,
                x: 0
            }}
            exit={{
                opacity: 0,
                x: 40
            }}
            transition={{
                opacity: {
                    ease: 'easeInOut',
                    duration: .4
                },
                x: {
                    ease: 'easeInOut',
                    duration: .3
                }
            }}
        >
            {children}
        </motion.div>
    );
};

export default MotionSlideInFromRight;
