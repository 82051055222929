import FactoryGameWorksLogo from "../../../src/assets/images/factory-logos/1.png";
import FactoryMegaCorpLogo from "../../../src/assets/images/factory-logos/2.png";
import FactoryIndieHouseLogo from "../../../src/assets/images/factory-logos/3.png";
import FactoryLevelUpLogo from "../../../src/assets/images/factory-logos/4.png";
import FactoryStudio64Logo from "../../../src/assets/images/factory-logos/5.png";
import FactoryPixelDenLogo from "../../../src/assets/images/factory-logos/6.png";
import FactoryRetroParadiseLogo from "../../../src/assets/images/factory-logos/7.png";
import FactoryGameForgeLogo from "../../../src/assets/images/factory-logos/8.png";


// Export map of factory names to their corresponding logos
export const factoryImageMap = {
    "Game Worx": FactoryGameWorksLogo,
    "Mega Corp": FactoryMegaCorpLogo,
    "Indie House": FactoryIndieHouseLogo,
    "Level Up": FactoryLevelUpLogo,
    "Studio 64": FactoryStudio64Logo,
    "Pixel Den": FactoryPixelDenLogo,
    "Retro Paradise": FactoryRetroParadiseLogo,
    "Game Forge": FactoryGameForgeLogo,
};

// also export helper function
export const getFactoryLogo = (factoryName) => {
    return factoryImageMap[factoryName];
};
