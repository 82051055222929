import React from 'react';
import './help.scss';
import Icon from "../../../../common/components/atoms/Icon/Icon";
import { useGetLeaderboard } from "../../../../data/useGetLeaderboard";

const GameHelp = (props) => {
    const {isPending, isError, data} = useGetLeaderboard();
    if (isPending) {
        // loading state
        return <></>;
    }

    if (isError) {
        // error page
        return <>
            <h1>
                <Icon name="info"/>
                Info
            </h1>

            <p className="game-modal-sub-heading small">Unknown error getting data</p>
        </>
    }
    var players = "many"
    data.forEach((f) => {
        if (f.name === props?.factoryName) {
            players = f.members;
        }
    });
    return (
        <section className="game-help">
            <h1 className="modal-content-header">
                <Icon name="info"/>
                Quick Start Guide
            </h1>

            <article className="info-content">
                <p className="section-heading">
                    <b>Goal</b>
                </p>
                <p className="small">
                    <strong>Maximize your factory's revenue to win the biggest cut of a $1,000,000 $SLING reward!</strong>
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Swap Factory (Coming soon)</b>
                </p>
                <p className="small">
                    Switch factories and take your revenue with you. Strategic moves toward the end of the season may reap huge rewards!
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Ranking (Coming soon)</b>
                </p>
                <p className="small">
                    <ul>
                        <li>Your rank in the factory depends on how much revenue you have generated compared to other
                            members of your factory.
                        </li>
                        <li>The higher your rank, the bigger slice of the factory winning you get at the end of the
                            season.
                        </li>
                    </ul>
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Your Role</b>
                </p>
                <p className="small">
                    <ul>
                        <li>You work alongside others in your chosen factory to generate revenue as a team</li>
                        <li>You compete against the other factories to take the top spot on the leaderboard</li>
                        <li>Your earnings, along with your factories members earnings contribute to your factory's total
                            revenue.
                        </li>
                    </ul>
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Production Line</b>
                </p>
                <p className="small">
                    <ul>
                        <li>Your production line has three sections, Ideas, Studio and Launch.</li>
                        <li>Use upgrades and boost to speed up production on each section and generate more revenue.</li>
                        <li>Locked sections will unlock as the season progresses.  </li>
                    </ul>
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Board</b>
                </p>
                <p className="small">
                    The leaderboard shows your factory's rank. The higher the rank, the bigger the reward at the end of the season. Remember, though, the prize is split between members. Does it make sense to move to a smaller factory? 👀
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Boosts & Upgrades</b>
                </p>
                <p className="small">
                    <ul>
                        <li>Upgrade with in-game revenue</li>
                        <li>Earn extra revenue with Boosts</li>
                    </ul>
                </p>
            </article>

            <article className="info-content">
                <p className="section-heading">
                    <b>Remember: Compete, collaborate, and climb to the top!</b>
                </p>
            </article>
        </section>
    );
};

export default GameHelp;
