export const boostPrices = {
    ui0: [
        10, 20, 30, 40, 50, 60, 100, 150, 200, 250,
        350, 450, 550, 650, 800, 950, 1100, 1250, 1400, 1550,
        1700, 1900, 2100, 2200, 24000, 26000, 28000, 30000, 32000, 34000,
        37000, 40000, 43000, 46000, 49000, 52000, 55000, 58000, 61000, 64000,
        680000, 720000, 760000, 800000, 840000, 880000, 920000, 960000, 1000000, 1040000, "MAX"
    ],
    ui1: [10, 50, 100, 250, 700, 20000, 50000, 100000, 500000, 1000000, "MAX"],
    ui2: [100, 500, 1000, 2500, 10000, 200000, 500000, 1000000, 5000000, 10000000,"MAX"],

    us0: [1000000, 2000000, 3000000, 4000000, 5000000, "MAX"],   // Quality
    us1: [9000000, 10000000, 11000000, 13000000, 15000000, "MAX"],   // Turbo
    us2: [10000000, 15000000, 20000000, 25000000, 30000000, 40000000, 50000000, 60000000, 70000000, 80000000, "MAX"],   // Success

    studio: 1000000,
}
