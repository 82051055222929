import React, {useEffect, useState} from 'react';
import './app-nav.scss';
import { getFactoryLogo } from "../../../../../src/common/utils/factoyLogos";
import NavItem from "../../molecules/NavItem/NavItem";
import Icon from "../../atoms/Icon/Icon";


const AppNav = ({
    className,
    forceActive,
    ...props
}) => {
    const componentName = 'app-nav';
    const [activeItem, setActiveItem] = useState(forceActive);

    const handleNavClick = (clicked) => {
        setActiveItem(clicked === activeItem ? '' : clicked);
        props.clicked(clicked);
    };

    //  sub content "home" in the game can trigger other navs so need to listen to those changes
    useEffect(() => {
        setActiveItem(forceActive);
    }, [forceActive]);

    return (
        <aside
            className={`${componentName} ${className}`}
        >
            <div className="app-nav-header">
                <img
                    className="icon-factory-logo"
                    src={getFactoryLogo(props?.factoryName)}
                    alt={`${props?.factoryName} icon`}
                />

                <ul className="stats">
                    <li>
                        <span className="caption">
                            {props?.factoryName}
                        </span>

                        {/*TODO not sure if toLocalString correct way to do this */}
                        ${props?.factoryScore?.toLocaleString()}
                    </li>
                    <li>
                         <span className="caption">
                             <Icon name="stopwatch"/>
                             Game Ends
                         </span>
                        {props?.gameEnds ?? 'TBD'}
                    </li>
                    <li>
                         <span className="caption">
                             <Icon name="medal"/>
                             Rank
                         </span>
                        {props?.rank}
                    </li>
                </ul>
            </div>

            <div className="app-nav-ctas">
                <NavItem
                    isActive={activeItem === 'home'}
                    clicked={() => handleNavClick('home')}>
                    <Icon name="home" />
                    Home
                </NavItem>
                <NavItem
                    isActive={activeItem === 'leaderboard'}
                    clicked={() => handleNavClick('leaderboard')}>
                    <Icon name="trophy" />
                    Board
                </NavItem>
                <NavItem
                    isActive={activeItem === ''}
                    clicked={() => handleNavClick('')}
                >
                    <Icon name="factory" />
                    Factory
                </NavItem>
                <NavItem
                    isActive={activeItem === 'boosts'}
                    clicked={() => handleNavClick('boosts')}>
                    <Icon name="fire" />
                    Boosts
                </NavItem>
            </div>
        </aside>
    );
};

export default AppNav;
