import React, {useEffect, useRef, useState} from 'react';
import './idea-concept.scss';
import ImageIdeaType1 from "../../../../assets/images/game-assets/ideas/idea-unicorn.png";
import ImageIdeaType2 from "../../../../assets/images/game-assets/ideas/idea-treasure.png";
import ImageIdeaType3 from "../../../../assets/images/game-assets/ideas/idea-boxing-glove.png";
import ImageIdeaType4 from "../../../../assets/images/game-assets/ideas/idea-flask.png";
import ImageIdeaType5 from "../../../../assets/images/game-assets/ideas/idea-gems.png";
import ImageIdeaType6 from "../../../../assets/images/game-assets/ideas/idea-quest.png";
import ImageIdeaType7 from "../../../../assets/images/game-assets/ideas/idea-football.png";
import ImageIdeaType8 from "../../../../assets/images/game-assets/ideas/idea-wheel.png";
import ImageIdeaType9 from "../../../../assets/images/game-assets/ideas/idea-helmet.png";
import CloudDisolve1 from "../../../../assets/images/game-assets/ideas/cloud-disolve-1.png";
import CloudDisolve2 from "../../../../assets/images/game-assets/ideas/cloud-disolve-2.png";
import CloudDisolve3 from "../../../../assets/images/game-assets/ideas/cloud-disolve-3.png";
import CloudDisolve4 from "../../../../assets/images/game-assets/ideas/cloud-disolve-4.png";


const imageMap = {
    type1: ImageIdeaType1,
    type2: ImageIdeaType2,
    type3: ImageIdeaType3,
    type4: ImageIdeaType4,
    type5: ImageIdeaType5,
    type6: ImageIdeaType6,
    type7: ImageIdeaType7,
    type8: ImageIdeaType8,
    type9: ImageIdeaType9,
};

const IdeaConcept = ({
    ideaType = 'type1',
    onAnimationEnd,
    scroreEnd,
    scroreStart,
    speed,
    status,
    ...props
}) => {
    const componentName = 'idea-concept';
    const ideaRef = useRef(null);
    const [ideaState, setIdeaState] = useState('deciding');

    useEffect(() => {
        const handleAnimationEnd = (event) => {
            if (event.animationName === 'preDecide') {
                onAnimationEnd('decision', status);   //  emits
            }

            if (event.animationName === 'postDecide' || event.animationName === 'ideaFail') {
                onAnimationEnd('end', status);   //  emits
            }

            setIdeaState(status);
        };

        const ideaElement = ideaRef.current;

        if (ideaElement) {
            ideaElement.addEventListener('animationend', handleAnimationEnd);
        }

        return () => {
            if (ideaElement) {
                ideaElement.removeEventListener('animationend', handleAnimationEnd);
            }
        };
    }, [onAnimationEnd]);

    return (
        <>
            {/* fade between images, will give better control of speed/transition */}
            <div
                className={`${componentName} speed-${speed} ${ideaState}`}
                ref={ideaRef}
            >
                {ideaState !== 'fail' && (
                    <>
                        <img
                            alt={"An idea being generated"}
                            src={imageMap[ideaType]}
                        />

                        <span className="score start">+{scroreStart}</span>
                        <span className="score end">${scroreEnd}</span>
                    </>
                )}

                {ideaState === 'fail' && (
                    <>
                        <img
                            alt="Rejected idea disolving"
                            src={CloudDisolve1}
                        />
                        <img
                            alt="Rejected idea disolving"
                            src={CloudDisolve2}
                        />
                        <img
                            alt="Rejected idea disolving"
                            src={CloudDisolve3}
                        />
                        <img
                            alt="Rejected idea disolving"
                            src={CloudDisolve4}
                        />
                    </>
                )}
            </div>



            {/*flickering out animation (need to ensure it's on the fail across the speeds, currently removed on speed 5 for the cloud fade above*/}
            {/*<div*/}
            {/*    className={`${componentName} speed-${speed} ${ideaState}`}*/}
            {/*    ref={ideaRef}*/}
            {/*>*/}
            {/*    {ideaState != 'fail' && <span className="score start">[START]</span>}*/}
            {/*    <img*/}
            {/*        alt={"An idea being generated"}*/}
            {/*        src={imageMap[ideaType]}*/}
            {/*    />*/}
            {/*    {ideaState === 'fail' && <span className="score end">[END]</span>}*/}
            {/*</div>*/}
        </>
    );
};

export default IdeaConcept;
