import React from "react";
import './invite.scss';
import Icon from "../../../../common/components/atoms/Icon/Icon";
import Modal from "../../../../common/components/molecules/Modal/Modal";


const Invite = ({
    onClose,
    ...props
}) => {
    const referralCode = '1447583';
    const inviteLink = `https://game.slingshotdao.com/join/${referralCode}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                alert('Invite link copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleCloseModal = () => {
        onClose();
    }

    return (
        <Modal
            className="invite-modal"
            header={
                <span
                    className="close"
                    onMouseDown={handleCloseModal}
                >
                    Close
                    <Icon name="close" />
                </span>
            }
        >
            <h1>
                <Icon name="users"/>
                Invite
            </h1>

            <p className="game-modal-sub-heading small">
                Invite your friends to join your factory and watch your revenue soar! Together, you’ll climb the
                leaderboard and dominate the competition!
            </p>

            <span className="invite-link">{inviteLink}</span>

            <button
                className="btn full"
                onMouseDown={copyToClipboard}
            >
                <Icon name="file"/>
                Copy Link
            </button>
        </Modal>
    );
};

export default Invite;
